import { SiteDataOK } from "../types/SiteData";



const useSiteData = () => {
    const updateSite = async (siteIDs: string[], option: string = "") => {
        let allPluginData: SiteDataOK[] = [];

        const fetchPromises = siteIDs.map((siteID) => {
            const cmd = option === "force" ? "/?cmd=force" : "";
            return fetch(`${process.env.REACT_APP_API_DOMAIN}/authorized/${siteID}${cmd}`, {
                method: "GET",
                signal: AbortSignal.timeout(5000)
            })
                .then(response => response.json())
                .then((data: SiteDataOK) => {
                    allPluginData.push(data);
                })
                .catch(error => {
                    console.log(error);
                });
        });

        // Wait for all fetch calls to complete
        await Promise.all(fetchPromises);
        return allPluginData; // Now, this will have the correct data
    };

    // Get plugin site and give it the plugin name
    const pluginData = async (authorizedList: string[]) => {
        const sites: SiteDataOK[] = await updateSite(authorizedList);
        const pluginsMap: Record<string, string[][]> = {};
        sites.forEach(site => {
            site.plugins.forEach(plugin => {
                const pluginName: string = plugin.name;
                const pluginCurrentVersion: string = plugin.version;
                const pluginLatestVersion: string = plugin.newVersion;
                const pluginInfos = []
                if (!pluginsMap[pluginName]) {
                    pluginsMap[pluginName] = [];
                    pluginsMap[pluginName].push([pluginLatestVersion]);
                }
                pluginInfos.push(site.name);
                pluginInfos.push(pluginCurrentVersion);
                pluginInfos.push(site.wpUrl);
                pluginsMap[pluginName].push(pluginInfos);
            });
        });
        // sort object by alphabetical order
        const sortedPluginsMap: any = Object.entries(pluginsMap)
            .sort()
            .reduce((r, [key, value]) => ({ ...r, [key]: value }), {});
        return sortedPluginsMap;
    }

    return { pluginData }; // useEffect ==> qui se fait après le clique sur le bouton
}
export default useSiteData;
