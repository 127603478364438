import { useEffect, useState } from "react";
import './PluginTable.scss';


const PluginTable = ({ pluginBySite }: { pluginBySite: Promise<Record<string, string[][]>>; }) => {
    const [pluginData, setPluginData] = useState<Record<string, string[][]> | null>(null);

    useEffect(() => {
        pluginBySite.then((value) => {
            setPluginData(value);
        });
    }, [pluginBySite]);

    return (
        <article>
            {pluginData ? (
                Object.entries(pluginData).map(([plugin, sites]) => (
                    <details key={plugin}>
                        <summary>
                            <div className="plugin-list__infos">
                                <header>
                                    <h3 className="site-list__title">{plugin}</h3>
                                    <div className="priority-tag site-name-updated">
                                        <p>{sites[0]}</p>
                                    </div>
                                </header>
                            </div>
                        </summary>
                        <div className="table__container display-none">
                            <table>
                                <tbody>
                                    {sites.slice(1).map((infos, index, array) => (
                                        <tr key={index} className={index === 0 ? "table__row--no-border" : ""}>
                                            {index === 0 && (
                                                <th scope="rowgroup" rowSpan={array.length}>
                                                    Sites
                                                </th>
                                            )}
                                            {infos.map((info, idx) => (
                                                idx === 2 ? <td className={"site-option-" + idx} key={`${index}-${idx}`}><a href={info}>{info}</a></td> : <td className={"site-option-" + idx} key={`${index}-${idx}`}>{info}</td>

                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </details>
                ))
            ) : (
                <p>Loading...</p>
            )}
        </article>
    );
}

export default PluginTable;
